<template>
	<div class="trialreport">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾体验报告"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		right-arrow
		:right-text="info.content_feedback?'':'分享'"
		@click-right="show=true"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 客户信息 -->
		<div style="height: 14px;"></div>
		<div class="customer">
			<div class="customer__title">
				客户信息
			</div>
			<div class="customer__number">
				{{info.number}}
			</div>
			<div class="customer__row">
				<div class="customer__row__title">{{info.name}}</div>
				<img style="margin-left: 10px;" v-if="info.gender == 1" src="@/assets/common/icon_man.png">
				<img style="margin-left: 10px;" v-if="info.gender == 0" src="@/assets/common/icon_woman.png">
			</div>
			<div class="customer__row">
				<div class="customer__row__title">联系方式：</div>
				<div class="customer__row__con">{{info.mobile}}</div>
			</div>
			<div class="customer__row">
				<div class="customer__row__title">试驾车型：</div>
				<div class="customer__row__con">{{info.model_name}} {{info.collocation_name}}</div>
			</div>
			<div class="customer__row">
				<div class="customer__row__title">试驾时间：</div>
				<div class="customer__row__con">{{info.scsjTime.slice(0,info.scsjTime.indexOf(' '))}}</div>
			</div>
			<div class="customer__row">
				<div style="visibility: hidden;" class="customer__row__title">试驾时间：</div>
				<div class="customer__row__con">{{info.scsjTime.slice(info.scsjTime.indexOf(' '))}}</div>
			</div>
			<div class="customer__kdlk">
				<img src="@/assets/trial/icon_kdlk.png">
			</div>
		</div>
		
		<!-- 评价 -->
		<div class="comment" v-if="info?.content_feedback && info.content_feedback.length!=0">
			<div class="comment__title">
				<div>
					<span>最吸引您的车辆</span>
					<span>体验是哪些</span>
				</div>
				<div>
					<span>是否有需要改善</span>
					<span>的车辆体验</span>
				</div>
			</div>
			<div class="comment__list" v-for="item,index in info.content_feedback" :key="index">
				<div class="comment__list__title">
					{{index+1}},{{item.content}}
				</div>
				<div class="comment__list__img">
					<img v-if="item.attract_feedback == 1" src="@/assets/drivroute/icon_jxxz.png">
					<img v-else src="@/assets/drivroute/icon_jxwxz.png">
					<img v-if="item.better_feedback == 2" src="@/assets/drivroute/icon_jxxz.png">
					<img v-else src="@/assets/drivroute/icon_jxwxz.png">
				</div>
			</div>
		</div>
		
		<!-- 选项 -->
		<div class="opts">
			<div class="opts__title">
				静谧性能
				<img src="@/assets/trial/icon_sound2.png" style="width: 32px;height: 17px;" mode="">
			</div>
			<div class="opt__box">
				<div class="opt">
					<div class="opt__con">
						<div>{{info.idling_noise}}</div>
						<div>分贝</div>
					</div>
					<div class="opt__init1">
						车外最大噪音值
					</div>
					<div class="opt__init2">
						{{info.idling_noise_env}}
					</div>
				</div>
				<div class="opt">
					<div class="opt__con">
						<div>{{info.down_noise}}</div>
						<div>分贝</div>
					</div>
					<div class="opt__init1">
						车内最小噪音值
					</div>
					<div class="opt__init2">
						{{info.down_noise_env}}
					</div>
				</div>
			</div>
		</div>
		
		<!-- 选项 -->
		<div class="opts opts__bg">
			<div class="opts__title">
				空气净化效果
				<img src="@/assets/trial/icon_air2.png" style="width: 30px;height: 26px;" mode="">
			</div>
			<div class="opt__box">
				<div class="opt">
					<div class="opt__con">
						<div>{{info.clean_air}}</div>
					</div>
					<div class="opt__init1">
						户外空气指数
					</div>
					<div class="opt__init2">
						{{info.clean_air_env}}
					</div>
				</div>
				<div class="opt">
					<div class="opt__con">
						<div>{{info.down_air}}</div>
					</div>
					<div class="opt__init1">
						车内空气指数
					</div>
					<div class="opt__init2">
						{{info.down_air_env}}
					</div>
				</div>
			</div>
		</div>
		
		<!-- 评分 -->
		<div class="grade" v-if="info.score">
			<div class="grade__title">
				您对本次试乘试驾的评价
			</div>
			<div class="grade__number">
				{{info.score}}<span>分</span>
			</div>
			<div class="grade__init">
				您对本次试乘试驾评价为：{{info.score}}分（1-10），您非常愿意向朋友推荐凯迪拉克的产品。
			</div>
		</div>

		<van-overlay style="display: flex;align-items: center;justify-content: center;" z-index="100000" :show="show" @click="show=false">
			<div class="wrapper" style="color: #fff;" @click.stop>
				<div class="abs" @click="show = false">关闭<img src="@/assets/trial/icon_guambi.png" alt=""></div>
				<div class="title">试乘试驾体验报告</div>
				<div class="concat">请顾客先扫描下方企业微信二维码添加好友</div>
				<div class="qrimg" :class="{'isborder':wechat_qr_image == '' || wechat_qr_image == null}">
					<div @click="gouser" v-if="wechat_qr_image == '' || wechat_qr_image == null" class="user_wxupload_upload_content">
						<span class="titleupload">尚未上传</span>
						<span class="titleupload">企业微信二维码</span>
						<span class="concatupload">点击设置</span>
					</div>
					<img v-else :src="wechat_qr_image" >
					</div>
				<div class="botton" @click="onshare">通过企业微信发送</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { getDateTime6,getsignature } from '@/utils/utils.js'

export default {
	data() {
		return {
			wechat_qr_image:'',
			show:false,
			info: {
				number: '',
			},
		};
	},
	mounted() {
		if (this.$route.query?.number) this.info.number = this.$route.query.number
		this.getTrialReport()
		this.getUserInfo()
	},
	methods: {
				// 用户详情
		async getUserInfo(){
			const {data: result} = await this.$http.post('/api/user/get_userinfo_1_5_1')
			if (result.code == 1) {
				this.wechat_qr_image = result.data.res.wechat_qr_image
			}
		},
		// 修改二维码
		gouser(){
			this.$router.push({
				path: `/useredit`
			})
		},
		onshare(){
			const url = localStorage.getItem('share')
			this.$wx.invoke(
						"shareAppMessage", {
							title: '试乘试驾体验报告', // 分享标题
							desc: '', // 分享描述
							link: url+'/scsj/scsjsjfk/?number=' + this.info.number, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
							imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
							enableIdTrans: 0, // 是否开启id转译，不填默认为0
						}, function(res) {
								if (res.err_msg == "shareAppMessage:ok") {
									this.$toast.success('分享成功')						//正确处理
								}else {
									getsignature()					//错误处理
								}
						}
					);
					this.show=false
		},
		// 试驾报告
		async getTrialReport(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_report_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.scsjTime = getDateTime6(this.info.starttime, this.info.finishtime)
				if(!this.info.content_feedback){
					this.show=true
				}
				
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.trialreport{
	padding-bottom: 100px;
}
.isborder{
	border: 1px solid #000 !important;
}
.wrapper{
	width: 602px;
	height: 812px;
	position: relative;
	background: url(http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/tkbj.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	color: #191919;
	background-color: #000;
	
	padding: 48px;
	box-sizing: border-box;
	.botton{
		margin-top: 70px;
		width: 100%;
		height: 92px;
		background: #FA0037;
		line-height: 92px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		
		color: #ECECEC;
	}
	.qrimg{
		
		.user_wxupload_upload_content{
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			align-items: center;
			width: 236px;
			height: 236px;
			padding: 60px 0 0 0;
			top: 0;
			left: 0;
			img{
				width: 48px;
				height: 48px;
			}
			.titleupload{
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				opacity: 0.4;
				font-weight: normal;
				color: #101010;
				margin-top: 10px;
				line-height: 28px;
			}
			.concatupload{
				font-size: 44px;
				margin-top: 40px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #101010;
				line-height: 40px;
			}
		}
		margin: 40px auto 0;
		width: 320px;
		height: 320px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #fff;
		&__btn{
			&__row1{
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #9B9B9B;
				line-height: 36px;
			}
			&__row2{
				margin-top: 10px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #fff;
				line-height: 36px;
			}
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.concat{
		text-align: center;
		padding: 20px 0;
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #919191;
		line-height: 28px;
	}
	.title{
		text-align: center;
		padding: 20px;
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #101010;
		line-height: 40px;
	}
	.abs{
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		display: flex;
		align-items: center;
		font-weight: normal;
		color: #818181;
		line-height: 32px;
		img{
			margin-left: 10px;
			width: 32px;
			height: 32px;
		}
	}
}
.customer{
	margin: 0 auto;
	width: 686px;
	box-sizing: border-box;
	padding: 32px;
	background: #222222;
	border-radius: 24px;
	position: relative;
	&__title{
		margin-bottom: 40px;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #ECECEC;
		line-height: 40px;
	}
	&__row{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		height: 40px;
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
		}
		&__con{
			font-size: 28px;
			font-family: Regular;
			color: #FFFFFF;
		}
		img{
			margin-right: 20px;
			width: 32px;
			height: 32px;
		}
	}
	&__number{
		margin-bottom: 20px;
		font-size: 24px;
		font-family: Regular;
		color: #ECECEC;
		line-height: 24px;
	}
	&__car{
		display: inline-block;
		padding: 0 10px;
		line-height: 48px;
		background: #F3C846;
		border-radius: 8px;
		font-size: 28px;
		font-family: HYQiHeiY3-55;
		color: #333333;
	}
	&__kdlk{
		position: absolute;
		top: 36px;
		right: 32px;
		width: 96px;
		height: 36px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.comment{
	box-sizing: border-box;
	margin: 50px auto 0;
	width: 686px;
	padding: 32px;
	background: #222222;
	border-radius: 24px;
	&__title{
		padding: 32px 0;
		margin-bottom: 40px;
		border-bottom: 1px solid #333333;
		display: flex;
		justify-content: space-between;
		div{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		span{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #A1A1A1;
			line-height: 32px;
		}
	}
	&__list{
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 48px;
		}
		&__img{
			padding: 30px 50px 50px;
			display: flex;
			justify-content: space-between;
			img{
				width: 144px;
				height: 72px;
			}
		}
	}
}
.opts{
	margin: 50px auto 0;
	width: 686px;

	text-align: center;
	&__title{
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #ECECEC;
		img{
			margin-left: 12px;
		}
	}
	.opt__box{
		display: flex;
		justify-content: space-around;
		.opt{
			width: 280px;
			&__con{
				display: flex;
				justify-content: center;
				div:nth-child(1){
					font-size: 52px;
					font-family: Regular;
					color: #E8B01E;
					line-height: 62px;
				}
				div:nth-child(2){
					margin-left: 10px;
					font-size: 28px;
					font-family: HYQiHeiY3-45;
					color: #ECECEC;
					line-height: 62px;
				}
			}
			&__init1{
				margin: 10px auto 0;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				color: #ECECEC;
				line-height: 28px;
			}
			&__init2{
				margin: 40px auto 0;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				color: #F5D98F;
				line-height: 28px;
			}
		}
	}
}
.opts__bg{
	padding: 50px 0 70px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_report.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.grade{
	margin: 50px auto 0;
	padding: 60px 36px;
	text-align: center;
	width: 670px;
	box-sizing: border-box;
	height: 492px;
	background: url('http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/book_bj.png');
	background-repeat: 100% 100%;
	background-repeat: no-repeat;
	&__title{
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		font-weight: 400;
		color: #000000;
	}
	&__init{
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		text-align: left;
		font-weight: 400;
		color: #666666;
		line-height: 48px;
	}
	&__number{
		padding: 60px;
		font-size: 120px;
		font-family: HYQiHeiY3-45;
		font-weight: 500;
		color: #C8800D;
		span{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #000000;
		}
	}
	&__footer{
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: 400;
		color: #666666;
		line-height: 48px;
	}
}

.trial__close__box{
	padding: 20px 32px;
}
.trial__close__btn{
	width: 100%;
	line-height: 88px;
	background: #fa0037;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #F4F4F4;
	text-align: center;
}
</style>
